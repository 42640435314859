import logo from './assets/logo.svg';
import './App.css';
import {FaGamepad} from 'react-icons/fa'
import {FaBook} from 'react-icons/fa'
import {FaSteam} from 'react-icons/fa'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBpgVMeYn_ZHai1hfQ-tOYIhAZtm0ZiHDE",
  authDomain: "towards-the-unknown.firebaseapp.com",
  projectId: "towards-the-unknown",
  storageBucket: "towards-the-unknown.appspot.com",
  messagingSenderId: "184390306203",
  appId: "1:184390306203:web:1b08d2af5d897f935cb93e",
  measurementId: "G-YRP2WS2RGF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <>
    <div className="App">
    <div className='App-background'></div>
      <header className="App-header">
        <img className='App-logo' src={logo} alt="" />
        <p className='App-subtitle'>Dans <span>Towards The Unknown</span>, plongez dans la Faille et partez à la découverte des différents mystères qu'elle abrite. 
          Mais attention, quelque chose d’étrange émane des profondeurs. Entre <span>créatures mystiques</span> et <span>artefacts légendaires</span>, 
          vous allez devoir faire preuve de courage et de stratégie au cours de cette expédition. 
          Mourrez, <span>passez le flambeau à un autre explorateur</span> en direction de l’inconnu.</p>
        <div className='button-container'>
          <div className='button button1'><div className='button-icon'><FaSteam/></div><a href="https://store.steampowered.com/app/2232100/Towards_the_Unknown/" target="_blank">S'inscrire au Playtest</a></div>
          <div className='button button2'><div className='button-icon'><FaBook/></div><a href="https://forms.gle/HJ6xCEbNKWLx4fDh8" target="_blank">Questionnaire</a></div>
        </div>
      </header>
      <div>
        <div class="firefly"></div>
        <div class="firefly"></div>
        <div class="firefly"></div>
        <div class="firefly"></div>
        <div class="firefly"></div>
        <div class="firefly"></div>
        <div class="firefly"></div>
        <div class="firefly"></div>
        <div class="firefly"></div>
        <div class="firefly"></div>
        <div class="firefly"></div>
        <div class="firefly"></div>
        <div class="firefly"></div>
        <div class="firefly"></div>
        <div class="firefly"></div>
      </div>
    </div>
    </>
  );
}

export default App;
